<template>
  <router-view />
</template>

<script>
import { onMounted, onBeforeUnmount, watch } from 'vue';
import { useRoute } from 'vue-router';

export default {
  setup() {
    const route = useRoute();

    const applyBackgroundClass = () => {
      // Clean up all previous background classes
      document.body.classList.remove('background-image', 'backgroundadmin-image');

      // Apply the correct background based on the route
      if (route.name === 'root') {
        document.body.classList.add('background-image');
      } else if (route.name === 'login' || route.name === 'SuccessPage') {
        document.body.classList.add('backgroundadmin-image');
      }
    };

    // Apply background class when component is mounted
    onMounted(() => {
      applyBackgroundClass();
    });

    // Clean up background class when the component is destroyed
    onBeforeUnmount(() => {
      document.body.classList.remove('background-image', 'backgroundadmin-image');
    });

    // Watch route changes to reapply the background class as needed
    watch(() => route.name, applyBackgroundClass);
  }
};
</script>

<style lang="scss">
@import '/src/assets/global.css';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
