import { createRouter, createWebHistory } from 'vue-router'
import PageTwo from '../views/PageTwo.vue';
import CGMember from '../views/CGMemberView.vue';
import SuccessPage from '../views/SuccessPage.vue';

const routes = [

  { path: '/login', name: 'login', component: PageTwo },
  { path: '/', name: 'root', component: CGMember },
  { path: '/success', name: 'SuccessPage', component: SuccessPage }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
