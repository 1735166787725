<template>
  <div class="success-page">
    <h1>THANK YOU</h1>
    <h2>FOR YOUR SUBMISSION</h2>
    <div class="link-container">
      <router-link to="/" class="button-link">Submit more names</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SuccessPage',
};
</script>

<style scoped>
@font-face {
  font-family: 'Acumin Pro Wide';
  src: url('../assets/Acumin Pro Wide Book.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Kalista Serif';
  src: url('../assets/KalistaSerif-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.success-page {
  text-align: center;
  padding: 20px;
  margin: 20px;

}

.success-page h1 {
  text-align: center;
  color: #803BF7;
  font-family: Acumin Pro Wide;
  font-size: 300%;
  font-style: normal;
  font-weight: bold;
  line-height: 120%;
  letter-spacing: 4px;
  margin-bottom: 20px;
  margin-top: 10%;
}

.success-page h2 {
  text-align: center;
  color: #37008A;
  margin-bottom: -10px;
  margin-top: 5px;
  font-family: Kalista Serif;
  font-size: 120%;
  font-style: normal;
  font-weight: bold;
  line-height: 160%;
  letter-spacing: 4px;
}

.success-page p {
  color: #333;
}

.link-container {
  margin-top: 28px;
  /* Space above the link container */
}

.link-container a {
  text-decoration: none;
  margin: 0 10px;
  /* Space between the links */
}


.button-link {
  font-family: Acumin Pro Wide;
  font-size: 80%;
  font-weight: bold;
  display: inline-block;
  padding: 10px 20px;
  background-color: #A0F2C9;
  color: #803BF7;
  border: none;
  border-radius: 15px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 767px) {
  .success-page h1 {
    font-size: 34px;
    margin-bottom: 4px;
  }

  .success-page h2 {

    font-size: 14px;

  }
}
</style>