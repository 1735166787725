<template>
  <div v-if="visible" class="modal-overlay">
    <div class="modal">
      <h3>Remove</h3>
      <p class="friendToBeRemoved">{{ friendName }}</p>
      <div class="modal-buttons">
        <div><button class="confirmButton" @click="confirmDelete">Confirm</button></div>
        <div><button class="cancelButton" @click="cancelDelete">Cancel</button></div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['visible', 'friendName'],
  methods: {
    confirmDelete() {
      this.$emit('confirm');
    },
    cancelDelete() {
      this.$emit('cancel');
    }
  }
}
</script>

<style scoped>
@font-face {
  font-family: 'Acumin Pro Wide';
  src: url('../assets/Acumin Pro Wide Book.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Kalista Serif';
  src: url('../assets/KalistaSerif-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

h3 {
  font-family: Acumin Pro Wide;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 120%;
  padding: 8px 15px;
  margin-top: -10px;
  color: #803BF7;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background: #A0F2C9;
  padding: 20px;
  border-radius: 15px;
  text-align: center;
  width: 15%;
}


.modal-buttons {
  display: flex;
  flex-direction: column;
  /* Arrange buttons in a column */
  align-items: center;
  /* Center buttons horizontally */
  /* Space between buttons */
  /* Add space between content and buttons */
}


.confirmButton {
  font-family: Acumin Pro Wide;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 120%;
  padding: 8px 15px;
  background-color: #803BF7;
  color: #A0F2C9;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  border: 1px solid #803BF7;
  margin-bottom: 4px;
  width: 150px;
  /* Adds an outline with the specified color */
}

.cancelButton {
  font-family: Acumin Pro Wide;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 120%;
  padding: 8px 15px;
  background-color: #A0F2C9;
  color: #803BF7;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  border: 1px solid #803BF7;
  width: 150px;
  /* Adds an outline with the specified color */
}

.friendToBeRemoved {
  font-family: Acumin Pro Wide;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 120%;
  padding: 8px 15px;
  margin-top: -30px;
}

@media (max-width: 767px) {
  .modal {background: #A0F2C9;
  padding: 20px;
  border-radius: 15px;
  text-align: center;
  width: 50%;
  }
}
</style>